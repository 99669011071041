import { combineReducers } from "redux";

import { common } from "./Common";
import { user } from "./AdminUser";

// Combine all reducers into root reducer
export default combineReducers({
  common,
  user,
});
