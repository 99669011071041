// import { Title } from "react-bootstrap/lib/Modal";

export const validationMessages = {
  //Login Form Validation Messages
  PROVIDE_VALID_EMAIL: "Please enter valid email format.",
  PROVIDE_REGISTERED_EMAIL: "Please enter registered email.",
  PROVIDE_PASSWORD: "Please enter password.",
  PROVIDE_CONFIRM_PASSWORD: "Pleasae enter confirm password.",
  PROVIDE_VALID_PASSWORD:
    "The password must contain at least one lowercase alphabetical character, one uppercase alphabetical character, one numeric character, one special character and six characters or longer",
  PROVIDE_VALID_CONFIRM_PASSWORD:
    "Password and confirm password must be the same",

  //Customer Form Validation Messages
  PROVIDE_FULL_NAME: "Please enter full name.",
  PROVIDE_VALID_FULLNAME: "Please enter valid full name",
  PROVIDE_GENDER: "Please select gender.",
  PROVIDE_DOB: "Please select date of birth.",
  PROVIDE_COMPANY_NAME: "Please enter company name.",
  PROVIDE_MOBILE_NUMBER: "Please enter mobile number.",
  PROVIDE_COUNTRY: "Please enter country.",
  PROVIDE_STATE: "Please enter state.",
  PROVIDE_ADDRESS: "Please enter address.",
  PROVIDE_ZIPCODE: "Please enter zipcode.",
  PROVIDE_BIO: "Please enter bio.",
  PROVIDE_WEBSITE: "Please enter website.",
  PROVIDE_PASSWORD: "Please enter password.",
  PROVIDE_CUSTOMER_TYPE: "Please select customer type.",
  PROVIDE_ROLE_ID: "Please select role.",

  //Product Form Validations
  PROVIDE_PRODUCT_NAME: "Please enter product name.",
  PROVIDE_VALID_PRODUCTNAME: "Please enter valid product name.",
  PROVIDE_PRODUCT_CATEGORY: "Please select category.",
  PROVIDE_PRODUCT_CODE: "Please enter product code.",
  PROVIDE_PRODUCT_SKU: "Please enter product sku.",
  PROVIDE_PRODUCT_VERSION: "Please enter product version",
  PROVIDE_PRODUCT_PRICE: "Please enter product price.",
  PROVIDE_PRODUCT_DEMOURL: "Please enter product demo url.",
};

export const successMessages = {
  CUSTOMER_DETAILS_ADDED: "Customer details has been Added successfully.",
  CUSTOMER_DETAILS_UPDATED: "Customer details has been updated successfully.",
  CUSTOMER_DELETE: "Customer has been deleted successfully.",

  ADMIN_DETAILS_ADDED: "Admin details has been Added successfully.",
  ADMIN_DETAILS_UPDATED: "Admin details has been updated successfully.",
  ADMIN_DELETE: "Admin has been deleted successfully.",
  ADMIN_PROFILE_UPDATED: "Admin profile has been updated successfully.",
};

export const errorMessages = {
  INTERNAL_ERROR: "An internal server error occurred!",
  SOMETHING_WENT_WRONG: "Something went wrong!",
  SESSTION_EXPIRED: "Your session has been expired!",
};
