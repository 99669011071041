export const AuthRoutePath = {
  LOGIN: "/login",
  FORGOTPASSWORD: "/forgot-password",
  RESETPASSWORD: "/reset-password",
};

export const SideBarRoutePath = {
  DASHBOARD: "/dashboard",
  ORDER: "/order",
  VIEWORDER: "/order/view-order",
  ADDORDER: "/order/add-order",
  UPDATEORDER: "/order/update-order",
  CUSTOMER: "/customer",
  ADDCUSTOMER: "/customer/add-customer",
  UPDATECUSTOMER: "/customer/update-customer",
  VIEWCUSTOMER: "/customer/view-customer",
  FEEDBACK: "/feedback",
  FEEDBACKDETAILS: "/feedback/feedback-details",
  PACKAGE: "/package",
  ADDPACKAGE: "/package/add-package",
  PRODUCT: "/product",
  ADDPRODUCT: "/product/add-product",
  UPDATEPRODUCT: "/product/update-product",
  DOCUMENT: "/document",
  ADDDOCUMENT: "/document/add-document",
  CONTENT: "/document/content",
  ADDCONTENT: "/document/add-content",
  FAQ: "/faq",
  ADDFAQ: "/faq/add-faq",
  ROLE: "/role",
  ADDROLE: "/role/add-role",
  CATEGORY: "/category",
  ADDCATEGORY: "/category/add-category",
  FEATURE: "/feature",
  ADDFEATURE: "/feature/add-feature",
  COUPON: "/coupon",
  ADDCOUPON: "/coupon/add-coupon",
  COUPONUSAGE: "/coupon/coupon-usage",
  ADMIN: "/admin",
  ADDADMIN: "/admin/add-admin",
  UPDATEADMIN: "/admin/update-admin",
  ADMINPROFILE: "/my-profile",
  GENERALSETTINGS: "/general-settings",
  PAYMENTGATEWAY: "/payment-gateway",
  SOCIALSETTINGS: "/social-settings",
  EMAILTEMPLATE: "/email-templates",
  ADDEMAILTEMPLATE: "/add-email-template",
  SMTPSETTINGS: "/smtp-settings",
  NOTFOUND: "/404",
};
