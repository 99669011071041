import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import reducers from '../redux/reducers/index';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)
export const store = createStore(
    persistedReducer,
    (window.location.hostname.indexOf('localhost') > -1 || window.location.hostname.indexOf('front.indianic.net') > -1) ? composeEnhancers(applyMiddleware(thunk)) : applyMiddleware(thunk)
);
export const persistor = persistStore(store)