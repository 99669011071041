export * from "./Endcrypt";
export * from "./DateHelper";
export * from "./AuthHelper";
export * from "./HelperFunctions";
export * from "./constants/UrlConstant";
export * from "./constants/SystemMessages";
export * from "./constants/ReduxTypes";

export const isTouchDevice = () => {
  if ("ontouchstart" in window) {
    return true;
  }
  return false;
};
