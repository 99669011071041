import { ADMIN_DETAILS, SESSION_LOGOUT, UPDATE_ADMIN_DETAILS } from "../../utils";

const INITIAL_STATE = {
  admindetails: {},
};
export const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_DETAILS:
      return Object.assign({}, state, { admindetails: action.payload.data.userdetails });
    case UPDATE_ADMIN_DETAILS:
      return Object.assign({}, state, { admindetails: action.payload.data.data });
    case SESSION_LOGOUT:
      return Object.assign({}, state, {
        admindetails: {},
      });
    default:
      return state;
  }
};
