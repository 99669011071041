const {
  REACT_APP_NODE_ENV,
  REACT_APP_GOOGLE_SOCIAL_LOGIN_ID,
  REACT_APP_FACEBOOK_SOCIAL_APP_ID,
} = process.env;

const env = {
  production: {
    API_END_POINT: "",
    GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_SOCIAL_LOGIN_ID,
    FACEBOOK_APP_ID: REACT_APP_FACEBOOK_SOCIAL_APP_ID,
  },
  staging: {
    API_END_POINT: "",
    GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_SOCIAL_LOGIN_ID,
    FACEBOOK_APP_ID: REACT_APP_FACEBOOK_SOCIAL_APP_ID,
  },
  development: {
    API_END_POINT: "https://arsenic.indianic.dev/api/v1",
    GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_SOCIAL_LOGIN_ID,
    FACEBOOK_APP_ID: REACT_APP_FACEBOOK_SOCIAL_APP_ID,
  },
  local: {
    API_END_POINT: "http://localhost/arsenic/laravel-arsenic-api/public/v1",
    GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_SOCIAL_LOGIN_ID,
    FACEBOOK_APP_ID: REACT_APP_FACEBOOK_SOCIAL_APP_ID,
  },
};

module.exports = env[REACT_APP_NODE_ENV];

