import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { createBrowserHistory } from "history";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "rc-time-picker/assets/index.css";
import "react-phone-input-2/lib/style.css";
import "react-circular-progressbar/dist/styles.css";

// Custom Stylesheets
import "./assets/css/style.dev.css";

import { ProtectedRoute, UnProtectedRoute, AuthRoutePath } from "./utils";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={createBrowserHistory}>
            <AccessibleNavigationAnnouncer />
            {/* Order */}
            <Switch>
              <UnProtectedRoute exact path="/login" component={Login} />

              <UnProtectedRoute
                exact
                path={AuthRoutePath.FORGOTPASSWORD}
                component={ForgotPassword}
              />

              <UnProtectedRoute
                exact
                path={AuthRoutePath.RESETPASSWORD}
                component={ResetPassword}
              />

              <ProtectedRoute path="/" component={Layout} />

              {/* Feedback */}
              {/* <ProtectedRoute path="/feedback" component={Layout} /> */}

              {/* If you have an index page, you can remothis Redirect */}
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
